import React from "react"
import NavBar from "../components/header/nav-main"
import Footer from "../components/footer"
import SEO from "../components/seo"
import Form from "../components/hackathon/form"
import { AnimationWrapper } from "react-hover-animation"
import { Container, Row, Col } from "reactstrap"
import Codepen from "react-codepen-embed"


// Images
import Solly from "../images/SolySignup.png"
import HeroWorkshop from "../images/Qcon_Header.png"
// import HeroWorkshop from "../images/HeroWorkshop.png"

// Confettii
import Confetti from "react-confetti"

import "../css/workshop.css"

import {
  TwitterShareButton,
  FacebookShareButton,
  WhatsappShareButton,
  LinkedinShareButton,
  TwitterIcon,
  FacebookIcon,
  WhatsappIcon,
  LinkedinIcon,
} from "react-share"

const Workshop = () => {
  let social_string =
    "Having a great day at the #SolaceWorkshop with @solacedotcom learning about developing event-driven #microservices! Workshop hosted by @SolaceDevs\n"
  let social_link = "https://solace.dev/workshop"
  let hashtags = ["solace", "pubsub", "tutorial", "DEVCommunity"]
  const [visible, setVisible] = React.useState(false);

  social_string =
    "Currently at the #SolaceWorkshop with @solacedotcom hosted by @SolaceDev at @QCon! Learning about developing event-driven #microservices\n"
  
  return (
    <>
      <SEO
        title="workshop"
        image="https://solace-dev.netlify.app/solace-dev-meta-og-image-qcon-workshop.png"
      />
      <header>
        <NavBar />
      </header>
      <Confetti
        recycle={false}
        width={1990}
        height={900}
        numberOfPieces={400}
      />
      <section id="resources" className="pa4">
        {/* <Row>
          <Form />
        </Row> */}
        <Container>
          <Row className="justify-content-md-center">
            {/* <img
              className="mb-5"
              src={HeroWorkshop}
              alt="HeroWorkshop Solace"
            /> */}
          </Row>
          {/* <Row>
            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
              <h2>Want some Solace Swag? Share on social 👇</h2>
            </Col>
            <Col className="mb-4">
              <TwitterShareButton
                className="pr-1"
                url={social_link}
                title={social_string}
                hashtags={hashtags}
              >
                <TwitterIcon size={32} round={true} />
              </TwitterShareButton>
              <LinkedinShareButton
                className="pr-1 pl-1"
                url={social_link}
                summary={social_string}
              >
                <LinkedinIcon size={32} round={true} />
              </LinkedinShareButton>
              <FacebookShareButton
                className="pr-1 pl-1"
                url={social_link}
                quote={social_string}
                hashtags={hashtags}
              >
                <FacebookIcon size={32} round={true} />
              </FacebookShareButton>
              <WhatsappShareButton
                className="pr-1 pl-1"
                url={social_link}
                title={social_string}
              >
                <WhatsappIcon size={32} round={true} />
              </WhatsappShareButton>
            </Col>
          </Row> */}
        </Container>
        <Container>
          <Row>
            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
              <h2>Resources</h2>
            </Col>
          </Row>
          <Row>
            <Col xs={12} sm={12} md={6} lg={4} xl={4} className="mt4 mb3">
              <a
                href="https://codepen.io/solacedevs/pen/GRvXRGV"
                target="_blank"
              >
                <div className="workshop-card">
                  <div className="title">Access Codepen</div>
                  <div className="desc">
                    The sourcecode of the codepen in the bottom of the page!
                  </div>
                  <div className="link">Open >></div>
                </div>
              </a>
            </Col>
            <Col xs={12} sm={12} md={6} lg={4} xl={4} className="mt4 mb3">
              <a href="https://solace.com/blog/developer-guide-event-driven-development/" target="_blank">
                <div className="workshop-card">
                  <div className="title">Developer Journey to learning Event-Driven Architectures</div>
                  <div className="desc">
                    Read about how to get started with event-driven architectures from a developer perspective and learn the basic concepts
                  </div>
                  <div className="link">Read >></div>
                </div>
              </a>
            </Col>
            <Col xs={12} sm={12} md={6} lg={4} xl={4} className="mt4 mb3">
              <a href="http://bit.ly/solace-tutorials-workshop" target="_blank">
                <div className="workshop-card">
                  <div className="title">Solace API Tutorials</div>
                  <div className="desc">
                    Get up-to-speed in sending and receiving messages over open
                    APIs and protocols, Solace Messaging APIs, and Pivotal
                    Platform.
                  </div>
                  <div className="link">Learn More >></div>
                </div>
              </a>
            </Col>
            <Col xs={12} sm={12} md={6} lg={4} xl={4} className="mt4 mb3">
              <a href="http://bit.ly/solace-docker-workshop" target="_blank">
                <div className="workshop-card">
                  <div className="title">Getting Started With Docker</div>
                  <div className="desc">
                    Getting Started with PubSub+ Standard Edition
                  </div>
                  <div className="link">Learn More >></div>
                </div>
              </a>
            </Col>
            <Col xs={12} sm={12} md={6} lg={4} xl={4} className="mt4 mb3">
              <a href="http://bit.ly/solace-cloud-workshop" target="_blank">
                <div className="workshop-card">
                  <div className="title">Getting Started With Cloud</div>
                  <div className="desc">
                    Getting Started with PubSub+ Cloud Edition
                  </div>
                  <div className="link">Learn More >></div>
                </div>
              </a>
            </Col>
            {/* <Col xs={12} sm={12} md={6} lg={4} xl={4} className="mt4 mb3">
              <a
                href="http://bit.ly/solace-react-paho-workshop"
                target="_blank"
              >
                <div className="workshop-card">
                  <div className="title">React with Paho MQTT</div>
                  <div className="desc">
                    A react application using Paho MQTT that connects to Solace
                    Cloud and publishes a message, receives the message through
                    a subscription and displays it on the screen
                  </div>
                  <div className="link">Learn More >></div>
                </div>
              </a>
            </Col> */}
            {/* <Col xs={12} sm={12} md={6} lg={4} xl={4} className="mt4 mb3">
              <a href="http://bit.ly/scs-basic-workshop" target="_blank">
                <div className="workshop-card">
                  <div className="title">Spring Cloud Stream Basics</div>
                  <div className="desc">
                    Getting Started with Spring Cloud Stream
                  </div>
                  <div className="link">Learn More >></div>
                </div>
              </a>
            </Col> */}
            <Col xs={12} sm={12} md={6} lg={4} xl={4} className="mt4 mb3">
              <a href="http://bit.ly/python-mqtt-workshop" target="_blank">
                <div className="workshop-card">
                  <div className="title">
                    A Python application using Paho MQTT
                  </div>
                  <div className="desc">
                    A Python application using the Solace Web Messaging API
                  </div>
                  <div className="link">Learn More >></div>
                </div>
              </a>
            </Col>
          </Row>
        </Container>
        <Container>
          <Row className="justify-content-md-center">
            <Col xs={6} sm={4}>
              <AnimationWrapper>
                <a
                  href="http://bit.ly/solace-community-register-workshop"
                  target="_blank"
                  className="solly"
                >
                  {" "}
                  <img src={Solly} alt="Logo" />{" "}
                </a>
              </AnimationWrapper>
            </Col>
          </Row>
        </Container>
      </section>
      <section id="codepen" className="pa4">
        <Container>
        <Row>
          <Col>
            <h1>Subscriber</h1>
            <Codepen hash="GRvXRGV" user="solacedevs" height="800" defaultTab="result" preview="false"/>
          </Col>
          {visible && 
            <Col>
            <h1>Publisher</h1>
              <Codepen hash="ExvodGJ" user="solacedevs" height="800" defaultTab="result" preview="false"/>
            </Col>
          }
        </Row>
        </Container>
      </section>
      <Container>
      <button onClick={() => setVisible(!visible)}>
        {visible ? 'Hide Publishers' : 'Show Publishers'}
      </button>

      </Container>
      <Footer />
    </>
  )
}

export default Workshop
